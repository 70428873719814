<template>
  <div class="flows-single-list">
    <div class="d-flex justify-content-end mb-3">
      <router-link :to="{ name: 'call-campaign-create-flow' }">
        <button class="btn btn-primary">
          {{ $t('call-campaign.flows.create-flow') }}
        </button>
      </router-link>
    </div>
    <div class="flows-single-table">
      <data-table
        :data="callScenarios"
        :fields="translatedTableFields"
        :pagination="pagination"
        :loading="callScenariosLoading"
        @changePage="onPageChange($event)"
      >
        <template v-slot:actions="{ row }">
          <button
            class="btn btn-sm mx-2 btn-primary"
            @click="downloadTemplate(row)"
          >
            {{ $t('order-confirmation.flows.table.download-template') }}
          </button>
          <router-link
            :to="{
              name: 'call-campaign-edit-flow',
              params: { flowId: row.id }
            }"
          >
            <i class="fa fa-pencil"></i>
          </router-link>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import DataTable from '../../elements/Table.vue'
import callCampaignService from '../../services/callCampaign.service'

export default {
  data() {
    return {
      fields: [
        { accessor: 'name', header: 'order-confirmation.flows.table.name' },
        {
          accessor: 'description',
          header: 'order-confirmation.flows.table.description'
        },
        {
          accessor: 'actions',
          header: 'order-confirmation.flows.table.actions'
        }
      ],
      callScenarios: [],
      callScenariosLoading: false,
      pagination: {
        totalPages: 0,
        page: 1
      }
    }
  },
  components: { DataTable },
  mounted() {
    this.getCallScenarios()
  },
  computed: {
    translatedTableFields() {
      return this.fields.map(field => {
        return {
          ...field,
          header: this.$t(field.header)
        }
      })
    }
  },
  methods: {
    onPageChange(page) {
      this.pagination.page = page
      this.getCallScenarios(page)
    },
    async getCallScenarios(page = 1, limit = 10) {
      this.callScenariosLoading = true
      const result = await callCampaignService.getCallScenarios(page, limit)
      const { orderScenarios, ...pagination } = result
      this.callScenarios = orderScenarios
      this.pagination.totalPages = pagination.totalPages

      this.callScenariosLoading = false
    },
    downloadFile(data, fileName) {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      const blob = new Blob([data], { type: 'octet/stream' }),
        url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    },
    async downloadTemplate(flow) {
      const file = await callCampaignService.getScenarioTemplate(flow.id)
      this.downloadFile(file, `${flow.name}.csv`)
    }
  }
}
</script>

<style></style>
